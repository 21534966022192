import React, { useEffect, useState } from "react";

const EditUsersCompanies = ({ companies, handleChange, userCompanies }) => {
  const [checkedState, setCheckedState] = useState([]);

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? { ...item, checked: !item.checked } : item
    );

    setCheckedState(updatedCheckedState);
    handleChange({
      target: {
        name: "companies",
        value: updatedCheckedState
          .filter(({ checked }) => checked)
          .map(({ id }) => id),
      },
    });
  };

  useEffect(() => {
    if (userCompanies?.length && companies?.length) {
      const companiesIds = userCompanies.map((company) => company.id);
      const nonCheckedCompanies = companies.filter(
        ({ id }) => !companiesIds.includes(id)
      );

      nonCheckedCompanies.forEach((company) => (company.checked = false));

      setCheckedState(() => [...nonCheckedCompanies, ...userCompanies]);
    }
  }, [userCompanies, companies]);

  return (
    <>
      <label htmlFor="companies-component">Empresas</label>
      <div id="companies-component" className="create-user-companies-container">
        {checkedState?.map(({ name, checked, id }, i) => (
          <div
            key={i}
            className={`create-user-company${checked ? " success" : ""}`}
          >
            <label htmlFor={`company-id-${i}`}>{name}</label>
            <input
              id={`company-id-${i}`}
              name={name}
              value={id}
              checked={checked}
              type="checkbox"
              onChange={() => handleOnChange(i)}
              className="d-none"
            />
          </div>
        ))}
        {/* 
      <div className={`create-user-company`}>
        <label htmlFor={`company-id-1`}>name</label>
        <input
          id={`company-id-1`}
          name="name"
          value="1"
          type="checkbox"
          className="d-none"
        />
      </div> */}
      </div>
    </>
  );
};

export { EditUsersCompanies };
